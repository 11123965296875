@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.text-align{
  text-align: center;
}

.checkout-button {
  background-color: rgb(27, 115, 197);
  border-radius: 15px;
}

button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
.checkout-button:hover {
  filter: contrast(115%);
  background-color: aqua;
}
.checkout-button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
  background-color: blueviolet;
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}
