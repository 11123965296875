.footer {
    background-color: #daa81e !important;
    color: rgb(74, 6, 77);
    padding: 20px 0;
    box-shadow: 2px -2px 7px 0px rgb(74, 6, 77) !important;
    border-color: rgb(74, 6, 77) !important;
    opacity: 0.9;
  }
  
  .footer .container {
    display: flex;
    justify-content: center;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
    list-style-type: none;
  }
  
  .footer ul li {
    margin-bottom: 10px;
    list-style-type: none;
    display: inline-block;
    padding: 0 1rem;
    border-right: 1px solid rgb(74, 6, 77) !important
  }
  
  .footer ul li a {
    color: rgb(74, 6, 77);
    text-decoration: none;
;

  }
  
  .footer ul li a:hover {
    text-decoration: none;
  }
