
:root {
  --dark-blue: rgb(17, 12, 41);
  --purple: rgb(74, 6, 77);
  --gold: #daa81e;
  --light-gold: #ccb166;
}

.App {
  text-align: center;

}
body{
  background: url("../public/images/pattern.png") round !important;
  background-color: var(--dark-blue) !important;
}

a{
  text-decoration: none !important;
 }
/* .App-header {
  background-color: #282c34;
  min-height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 10px;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.typo{
  margin-top: 10px !important;
}
/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.8;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

button {
  background-color: var(--accent-color);
}

button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.fantasy{
  font-family: Bebas Neue !important;
}

.text-purple{
  color: var(--purple) !important;
}

.text-gold{
  color: var(--gold) !important;
}

.bg-gold{
  background-color: var(--gold) !important;
}

.bg-light-gold{
  background-color: var(--light-gold) !important;
}

.purple-font{
  color: var(--purple) !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}


.bg-purple{
  background-color:var(--purple) !important;
}

.outline-purple{
  border-color: var(--purple) !important;
  background-color: var(--gold) !important;
  color: var(--purple) !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: bolder !important;
  border-radius: 18px !important;
  border-width: 4px !important;
  transition: 0.7s !important;
}
.outline-purple:hover{
  color: var(--gold) !important;
  background-color: var(--purple) !important;
}
.inverted-outline-purple{
  border-color: var(--gold) !important;
  color: var(--gold) !important;
  background-color: var(--purple) !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: bolder !important;
  border-radius: 18px !important;
  border-width: 4px !important;
  transition: 0.7s !important;
}
.inverted-outline-purple:hover{
  background-color: var(--gold) !important;
  color: var(--purple) !important;
  border-color: var(--purple) !important;
}

.purple-border{
  box-shadow: 0px 1px 7px 0px rgb(239, 223, 240) !important;
  border-color: var(--purple) !important;
 
}

.card-header{
  letter-spacing: 1px;
}

.card-title{
  font-size:smaller !important;
  font-weight:800 !important;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.card-text{
  font-size:small !important;

}

.bio{
  font-size: x-large !important;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  white-space: pre-wrap !important;
}

.youtube-iframe{
  width:60%;
  height:220%;
}

@media (max-width: 501px) {
  button{
    font-size:small !important;
  }

  h4{
    font-size: medium !important;
  }

  .bio{
    font-size:small !important;
  }
  .youtube-iframe{
    width:100%;
    height:100%;
  }
}

.border-box{
  box-sizing: border-box !important;
}

.fade-in {
  animation-name: entrada;
  animation-duration: 10s;
  animation-iteration-count: initial;
  animation-timing-function: linear;
  transition: all 200ms;


}

@keyframes entrada {
  0% {
      opacity: 0;
  }

  50% {
      opacity: 05;
  }

  100% {

      opacity: 1;
  }

}

