@import '~@fortawesome/fontawesome-free/css/all.min.css';
.Header {
    text-align: center;
    background-color:rgb(74, 6, 77)!important;
    height: 25%;
    background-image: url("../../../public/images/gold_pattern.png") !important;
    background-size: contain;
    color: white;
    width: 100%;
  }

.Logo img {
    animation: App-logo-spin infinite 20s linear;
    height: 10%;
    width: 10%;
    margin-top: 20px;
  }

  @keyframes App-logo-spin {
    from { transform: rotate(360deg); }
    to { transform: rotate(0deg); }
  }

  .Menu {
      margin: 0;
      
      list-style: none;
      color:rgb(74, 6, 77);
  }

  .Menu li {
      display: inline-block;
      background-color: rgb(74, 6, 77);

  }

  .Menu li a {
      color: #daa81e;
      text-decoration: none;
      font-family: Bebas Neue;
      border-radius: 10px;
      display: block;
      padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
      font-size: 25px;
      font-weight: var(--bs-nav-link-font-weight);
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x);
      background: 0 0;
      border: 0;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  }

  .Menu li a:hover {
      color: rgb(74, 6, 77);
      background-color: #daa81e;

  }

.cart{
    font-size: 20px !important;
    border-radius: 0 0 0 4px !important;
    background-color:#daa81e !important;
    border-color: white !important;
    border-top-color: rgb(74, 6, 77) !important ;
    border-right-color: rgb(74, 6, 77) !important ;
    color: rgb(74, 6, 77) !important;
  }

  .cart:disabled{
    opacity: 0.9 !important;
  }
.Logo1{
  height: 100%;
}

.adjust_image{
  height:20%;
  width:25%;
  margin-top: 40px;
}
.item-count{
  color:rgb(74, 6, 77) !important;
  font-weight: bolder;

  border-radius: 25px;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .Logo img {

    height: 15%;
    width: 15%;
  
  }
}

@media (max-width: 501px) {
  .Menu li a {
    
    font-size: 18px;
   
}
.cart{
  font-size: 12px !important;
}
  

.adjust_image{

  margin-top: 20px;
}
.Logo img {

  height: 20%;
  width: 20%;

}


  }